import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Section from '~components/Section'
import Seo from "~components/Seo"
import { graphql } from 'gatsby'
import { DateTime } from 'luxon'
import Button from '~components/blocks/Button'
import SearchHeader from '~components/sections/SearchHeader'
import ProductCard from '~components/blocks/ProductCard'
import ArticleCard from '~components/blocks/ArticleCard'
import resolveLink from '~utils/resolveLink'
import { useQueryParams, StringParam } from 'use-query-params'

const Search = () => {

  const {mobile, tablet} = breakpoints

  const [results, setResults] = useState({})
  const [show, setShow] = useState('all')
  const [initialValue, setInitialValue] = useState(undefined)

  const formatDate = (date) => {
    const d = DateTime.fromISO(date)
    return DateTime.fromISO(date).toFormat('dd.MM.yy')
  }

  const [urlParams, setUrlParams] = useQueryParams({
    term: StringParam
  })

  useEffect(() => {
    if(urlParams.term){
      setInitialValue(urlParams.term)
    }
  }, [urlParams.term])

  return (
    <>
      <Seo
        title="Search"
				url="/search"
      />
      <SearchHeader css={css`margin-top: 30px;`} onUpdate={res => setResults(res)} onUpdateShow={res => setShow(res)} initialValue={initialValue}/>
      {(show === 'all' || show === 'products') && results.products?.length > 0 &&
      <Section>
        <h2 css={css`
          grid-column: span 3;
          margin-top: 100px;
          margin-bottom: 58px;
        `}>Products</h2>
        <div css={css`
          grid-column: span 12;
          display: grid;
          justify-items: left;
          grid-template-columns: repeat(3, 1fr);
          padding: 0 45px;
          grid-row-gap: 50px;
          grid-column-gap: 8%;
          ${mobile}{
            padding: 0 30px;
          }`}>
            {results?.products?.map(product => (
              <ProductCard css={css`
              grid-column: span 1; 
              width: 100%;
              @media (max-width: 750px){
                grid-column: span 3;
              }
              `}
                title={product.title}
                brand={product.brand}
                image={product.thumb?._ref}
                slug={product.slug}
                lifestyleImage={product.lifestyleThumb}
                price={product.priceFrom}
                cardDesc={product.cardDesc}
                productId={product._id}
                link={resolveLink('product', product.slug)}
              />
            ))}
        </div>
      </Section>
      }
      <Section css={css`
        margin-top: 100px;
        h2{
          grid-column: span 12;
          margin-bottom: 18px;
        }
        > div{
          grid-row-gap: 40px;
        }
      `}>
        {(show === 'all' || show === 'articles') && results.articles?.length > 0 &&
          <>
          <h2>Articles</h2>
          {results?.articles?.map(article => (
            <>
            <ArticleCard 
            image={article.thumb}
            link={resolveLink('article', article?.slug)}
            categories={article.categories} 
            date={formatDate(article.date)} 
            title={article?.title}
            css={css`
              grid-column: span 3;
              ${tablet}{
                grid-column: span 4;
              }
              ${mobile}{
                grid-column: span 12;
              }
              `}
            />
            </>
          ))}
          </>
        }
      </Section>
    </>
  )
}

export default Search
