import React, { useState, useLayoutEffect, useEffect } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { breakpoints } from '~styles/global'
import Section from '~components/Section'
import SearchInput from '~components/blocks/SearchInput'
import { useStaticQuery, graphql } from "gatsby"

const SearchHeader = ({ className, initialValue, onUpdate, onUpdateShow }) => {
  const [term, setTerm] = useState(undefined)
  const [allArticles, setAllArticles] = useState([])
  const [allProducts, setAllProducts] = useState([])
  const [show, setShow] = useState('all')
  const [counts, setCounts] = useState({
    all: 0,
    articles: 0,
    products: 0,
  })
  const { mobile } = breakpoints

  useEffect(() => {
    if(data.allSanityArticle.nodes){
      setAllArticles(data.allSanityArticle.nodes.map(node => ({
        title: node.title,
        id: node._id,
        date: node.datePublished,
        slug: node.slug.current,
        metaDescription: node.content?.seo?.metaDescription,
        categories: node.content?.categories,
        thumb: node.content?.thumb?._rawAsset
      })))

      setAllProducts(data.allSanityProduct.nodes.map(node => ({
        title: node.title,
        id: node._id,
        slug: node.slug?.current,
        cardDesc: node.content?.cardDesc,
        metaDescription: node.content?.seo?.metaDescription,
        thumb: node.content?.cardImage?._rawAsset,
        brand: node.content?.brand?.title,
        priceFrom: node.content?.priceFrom,
        lifestyleThumb: node.content?.cardLifestyleImage?._rawAsset,
				categories: node.content?.categories,
				materials: node.content?.materials,
				shapes: node.content?.shapes,
				finishes: node.content?.finishes?.map(finish => finish.name).join(' ') + ' ' +  node.content?.finishes?.map(finish => finish.sets?.map(set => set.name).join(' ')).join(' '),
      })))

    }
  }, [])

  useEffect(() => {
    if(initialValue?.length > 0){
      setTerm(initialValue)
    }
  }, [initialValue])

  const getScore = (post, searchTerm) => {
    let score = 0
    const words = searchTerm.toLowerCase().split(/(?:,| )+/)
    const title = post.title.toLowerCase().split(/(?:,| )+/)
    let titleIntersection = title.filter(x => words.includes(x))
    // You get 5 points for each word that's in the title
    score = score + titleIntersection.length * 5

    if(post.metaDescription){
      const meta = post.metaDescription.toLowerCase().split(/(?:,| )+/)
      let metaIntersection = meta.filter(x => words.includes(x))
      // You get a point for each word that's in the description
      score = score + metaIntersection.length
    }

		if(post.finishes){
      const finishes = post.finishes.toLowerCase().split(/(?:,| )+/)

			let depluralised = []
			finishes.forEach(f => {
				// console.log(f.slice(-1))
				if(f.slice(-1) === 's'){
					depluralised = [...depluralised, f.slice(0, -1)]
				}
			})
      let finishIntersection = [...finishes, ...depluralised].filter(x => words.includes(x))
      // You get a point for each word that's has a match in finishes
      score = score + finishIntersection.length
    }

		if(post.brand){
      const brandName = post.brand.toLowerCase().split(/(?:,| )+/)
      let brandIntersection = brandName.filter(x => words.includes(x))
      // You get two points for each word that's in the brand name
      score = score + brandIntersection.length * 2
    }

		if(post.categories){
			const categories = post.categories?.map(category => category.title?.toLowerCase().split(/(?:,| )+/)).flat() || []
			let catsIntersection = categories.filter(x => words.includes(x))
			score = score + catsIntersection.length
		}

		if(post.materials){
			const materials = post.materials?.map(category => category.title?.toLowerCase()) || []
			let matsIntersection = materials.filter(x => words.includes(x))
			score = score + matsIntersection.length
		}

		if(post.shapes){
			const shapes = post.shapes?.map(category => category.title?.toLowerCase()) || []
			let shapesIntersection = shapes.filter(x => words.includes(x))
			score = score + shapesIntersection.length
		}

    return score
  }


  // filter the posts when the term changes
  useEffect(() => {
    updateResults()
  }, [term])

  const updateResults = () => {
    if(term !== undefined){
      const articles = []
      const products = []

      allArticles.forEach((article, i) => {
        const score = getScore(article, term)
        if(score > 0){
          articles.push({...article, score: score})
        }
      })

      allProducts.forEach((product, i) => {
        const score = getScore(product, term)
        if(score > 0){
          products.push({...product, score: score})
        }
      })

      setCounts({
        all: articles.length + products.length,
        articles: articles.length,
        products: products.length,
      })


      function compare(a, b) {
        if (a.score > b.score) {
          return -1;
        }
        if (a.score < b.score) {
          return 1;
        }
        return 0;
      }

      articles.sort(compare)

      products.sort(compare)

      // Call the update function to trigger the parent page to update, with the new values in score order as the argument
      onUpdate({
        articles: articles,
        products: products,
      })

      // console.log({
      //   articles: articles,
      //   products: products,
      // })
    }
  }

  const updateShow = val => {
    setShow(val)
    onUpdateShow(val)
  }

  const data = useStaticQuery(graphql`
    query {
      allSanityArticle(sort: {order: ASC, fields: datePublished}) {
        nodes {
          _id
          content {
            seo {
              metaDescription
            }
            categories {
              title
            }
            thumb {
              _rawAsset
            }
          }
          title
          datePublished
          slug {
            current
          }
        }
      }
      allSanityProduct(filter: {active: {eq: true}}) {
        nodes {
          _id
          title
          slug {
            current
          }
          content {
            cardDesc
            seo {
              metaDescription
            }
            priceFrom
            brand {
              title
            }
            cardImage {
              _rawAsset
            }
            cardLifestyleImage {
              _rawAsset
            }
						categories {
							title
						}
						materials {
							title
						}
						shapes {
							title
						}
						finishes {
							name
							sets {
								... on SanityFinishSet {
									name
								}
								... on SanitySwatchSet {
									name
								}
							}
						}
          }
        }
      }
    }
  `)

  return (
    <Section
      className={className}
      css={css`
      `}
    >
    <h4 css={css`
      grid-column: span 12;
      margin-bottom: 48px;
      transition: opacity 0.4s;
      opacity: ${counts.all > 0 ? 1 : 0};
      ${mobile}{
        margin-bottom: 26px;
      }
      `}>{counts.all > 0 ? counts.all + ' ' : ''}Result{counts.all !== 1 ? 's' : ''} found for...</h4>
    <div css={css`
      grid-column: span 4;
      ${mobile}{
        grid-column: span 12;
        margin-bottom: 63px;
      }
      input{
        margin-bottom: 0;
      }
      `}>
      <SearchInput css={css`margin-bottom: 0`} initialValue={initialValue} onChange={e => setTerm(e)}/>
    </div>
    <div css={css`
      grid-column: 7 / 13;
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      max-width: 295px;
      ${mobile}{
        max-width: 220px;
        grid-column: span 12;
      }
      button{
        margin-right: 30px;
        position: relative;
        border-bottom: 1px solid transparent;
        padding-bottom: 1px;
        transition: border-color 0.4s;
        &:last-child{
          margin-right: 0;
        }
        &.active{
          border-bottom: 1px solid;
        }
      }
      .h6{
        display: inline-block;
        position: absolute;
        top: -2px;
        right: 0;
        transform: translateX(120%);
      }
      `}>
      <button onClick={() => updateShow('all')} className={show === 'all' ? 'active' : ''}>
        All<span className="h6">{counts.all}</span>
      </button>
      <button onClick={() => updateShow('products')} className={show === 'products' ? 'active' : ''}>
        Products<span className="h6">{counts.products}</span>
      </button>
      <button onClick={() => updateShow('articles')} className={show === 'articles' ? 'active' : ''}>
        Articles<span className="h6">{counts.articles}</span>
      </button>
    </div>
    </Section>
  )
}

SearchHeader.propTypes = {
  initialTerm: PropTypes.string,
  onUpdate: PropTypes.func,
  onUpdateShow: PropTypes.func,
}

SearchHeader.defaultProps = {
  initialTerm: null,
  onUpdate: e => console.log(e),
  onUpdateShow: e => console.log(e),
}

export default SearchHeader
